<template>
  <Portlet
    title="Video Publisher"
    icon="box"
    class="triggerList_Operation"
  >
    <template slot="buttons">
      <div class="btn btn-group-sm btn-group m-0 p-0 d-none d-md-block">
        <font-awesome-icon
          :class="['alt-pointer color-primary mr-2', { 'fa-spin' : loadingSessions || refreshing}]"
          icon="sync-alt"
          style="vertical-align: middle;"
          @click="getStatus()"
        />
        <router-link
          v-if="authenticationHasRole('admin') || authenticationHasRole('secondlevel')"
          :to="detailsUrl"
          title="View"
          class="btn btn-sm btn-secondary"
        >
          <font-awesome-icon
            class="gray mr-2"
            icon="edit"
          />
          <span>{{ $t('details') }}</span>
        </router-link>
        <button
          class="btn btn-sm btn-secondary"
          @click="openConfirmModal()"
        >
          <font-awesome-icon
            class="gray mr-2"
            icon="redo-alt"
          /><span>Restart</span>
        </button>
      </div>
    </template>
    <LoadingPlaceholder v-show="loadingSessions" />
    <template v-if="!loadingSessions && statusInformation">
      <div class="container-fluid">
        <div class="row">
          <div class="border-col col-12 col-xl-4">
            <div class="countTitle">
              {{ $t('lastHour') }}
            </div>
            <div class="row">
              <div class="col-6">
                <div class="total"> 
                  {{ statusInformation.totalSessionsLastHour }}
                </div>
                <div class="totalLabel">
                  {{ $t('sessions') }}
                </div>
              </div>
              <div class="col-6">
                <div class="detailCounts">
                  <font-awesome-icon
                    class="mr-2"
                    icon="clock"
                  />
                  <span>{{ statusInformation.waitingSessionsLastHour }}  {{ $t('waiting') }} </span>
                </div>
                <div class="detailCounts">
                  <font-awesome-icon
                    class="mr-2"
                    icon="bug"
                  />
                  <span>{{ statusInformation.faultySessionsLastHour }}  {{ $t('faulty') }}</span>
                </div>
                <div class="detailCounts">
                  <font-awesome-icon
                    class="mr-2"
                    icon="upload"
                  />
                  <span>{{ statusInformation.uploadedSessionsLastHour }}  {{ $t('uploaded') }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="border-col col-12 col-xl-4">
            <div class="countTitle">
              Total
            </div>
            <div class="row">
              <div class="col-6">
                <div class="total"> 
                  {{ statusInformation.totalSessions }}
                </div>
                <div class="totalLabel">
                  {{ $t('sessions') }}
                </div>
              </div>
              <div class="col-6">
                <div class="detailCounts">
                  <font-awesome-icon
                    class="mr-2"
                    icon="clock"
                  />
                  <span>{{ statusInformation.waitingSessions }}  {{ $t('waiting') }} </span>
                </div>
                <div class="detailCounts">
                  <font-awesome-icon
                    class="mr-2"
                    icon="bug"
                  />
                  <span>{{ statusInformation.faultySessions }}  {{ $t('faulty') }}</span>
                </div>
                <div class="detailCounts">
                  <font-awesome-icon
                    class="mr-2"
                    icon="upload"
                  />
                  <span>{{ statusInformation.uploadedSessions }}  {{ $t('uploaded') }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="border-col col-12 col-xl-4">
            <div class="countTitle">
              {{ $t('uploads') }}
            </div>
            <div class="row">
              <div class="col-5">
                <div class="totalRight"> 
                  {{ statusInformation.activeUploads }}
                </div>
                <div class="totalLabelRight">
                  {{ $t('active') }}
                </div>
              </div>
              <div class="col-2">
                <div class="total"> 
                  /
                </div>
              </div>
              <div class="col-5">
                <div class="totalLeft"> 
                  {{ statusInformation.waitingUploads }}
                </div>
                <div class="totalLabelLeft">
                  {{ $t('waiting') }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <p
      v-else
    >
      {{ $t('noDataAvailable') }}
    </p>
    <SweetModal
      ref="confirmRestart"
      title="Restart?"
      icon="warning"
      blocking
      class="overflowHidden"
    >
      <p>{{ $t('sureToRestart', {'0' : 'VideoPublisher'}) }}</p>
      <button
        slot="button"
        class="btn btn-secondary float-left mb-3"
        @click="$refs.confirmRestart.close()"
      >
        <font-awesome-icon
          class="mr-2"
          icon="times"
        />{{ $t('cancel') }}
      </button>
      <button
        slot="button"
        class="btn btn-primary float-right mb-3"
        @click="restartVideoPublisher()"
      >
        <font-awesome-icon
          class="mr-2"
          icon="redo-alt"
        />{{ 'Restart' }}
      </button>
      <div class="clearfix" />
    </SweetModal>
  </Portlet>
</template>
<script>
import { SweetModal } from 'sweet-modal-vue';
import { authenticationMixin } from '@/mixins/authenticationMixin';

export default {
  name: 'VideoPublisherStatus',
  components: {
    SweetModal
  },
  mixins: [
    authenticationMixin,
  ],
  props: {
    installationId: {
      type: String,
      required: true
    },
    laneNumber: {
      type: String,
      required: false,
      default () {
        return null;
      }
    }
  },
  data () {
    return {
      statusInformation: {},
      loadingSessions: true,
      refreshInterval: null,
      refreshing: false
    }
  },
  computed: {
    detailsUrl: function () {
      if (!this.laneNumber) 
      {
        return `/installation/${ this.installationId }/tracker/videopublisher`;
      }
      else //its a Dualslalom installation with own routing
      {
        return `/installation/${ this.installationId }/tracker/videopublisher/lane/${ this.laneNumber }`;
      }
    }
  },
  created () {
    this.getStatus();
    this.createInterval();
  },
  beforeDestroy () {
    this.cancelInterval();
  },
  methods: {
    openConfirmModal () {
      this.$refs.confirmRestart.open();
    },
    createInterval () {
      this.refreshInterval = window.setInterval(() => {
        this.getStatus();
      }, 2000);
    },
    cancelInterval () {
      window.clearInterval(this.refreshInterval);
      this.refreshInterval = null;
    },
    //#region API-calls
    getStatus () {
      this.refreshing = true;
      let url = `/VideoPublisher/GetStatus/${ this.installationId }`; 
      if (this.laneNumber)
      {
        url += `?laneNumber=${ this.laneNumber }`;
      }
      this.axios.get(url)
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.data == null) {
            return;
          }
          this.statusInformation = response.data;
        })
        .catch(() => {
          if (this.axios.isCancel()) {
            return;
          }
        })
        .finally(() => {
          this.loadingSessions = false;
          this.refreshing = false;
        });
    },
    restartVideoPublisher () {
      this.$refs.confirmRestart.close();
      this.axios.get(`/Endpoint/GetTrackerDevice?installationId=${this.installationId}&componentType=VideoPublisher&laneNumber=${this.laneNumber}`)
        .then((response) => {
          if (response) {
            let commandRequest = {
              'Command': 'restartVideoPublisher',
              'Args': ''
            };
            if (response.data && response.data.uuid)
            {
              this.axios.post(`/CentralDeviceManagement/ExecuteCommand?deviceUuid=${ response.data.uuid }`, commandRequest)
                .then((response) => {
                  this.$snotify.info(response.data.output);
                })
                .catch(() => {
                   this.$snotify.error("Can not restart VideoPublisher");
                });
            }
        }
        });
    }
  //#endregion
  }
}
</script>

<style scoped>
.border-col {
  border: 1px solid #ececec;
  box-sizing: border-box;
  display: table-cell;
  padding-bottom: 4px;
}

.total {
  font-size: 3.5rem;
  text-align: center;
  line-height: 4rem;
  padding-top: 6px;
}
.totalRight {
  font-size: 3.5rem;
  text-align: right;
  line-height: 4rem;
  padding-top: 6px;
}
.totalLeft {
  font-size: 3.5rem;
  text-align: left;
  line-height: 4rem;
  padding-top: 6px;
}

.totalLabel {
  font-size: 1rem;
  text-align: center;
  vertical-align: top;
}
.totalLabelRight {
  font-size: 1rem;
  text-align: right;
  vertical-align: top;
}
.totalLabelLeft {
  font-size: 1rem;
  text-align: left;
  vertical-align: top;
}

.detailCounts {
  font-size: 1rem;
  line-height: 1.33rem;
  padding-top: 6px;
}

.countTitle {
  font-size: 1.1rem;
  line-height: 1.5rem;
  padding-top: 6px;
  padding-bottom: 6px;
  text-align: center;
}
</style>
